import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AiIntelligence from '../services/aiIntelligence.service'
function CategoryPrediction() {
    const [title, setTitle] = useState('')
    const [data, setData] = useState(null)

    const handleOnChange = (e) => {
        e.preventDefault()
        setTitle(e.target.value)
    }

    const predictCategory = async () => {
        const data = await AiIntelligence.searchCategory({ title })
        setData(data)
    }

    return (
        <div className="container">
            <div className="mt-3">
                <Link to="/allresources" className="bt-home">
                    <i className="fa fa-angle-left mx-2"></i> Back to Home
                </Link>
            </div>
            <div className="smart-search mt-5 ps-0 predict-category">
                <input
                    type="search"
                    placeholder="search"
                    className="form-control"
                    onChange={(e) => handleOnChange(e)}
                />
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={predictCategory}
                >
                    Search
                </button>
            </div>

            {data != null && (
                <div className="card card-body mt-4">
                    <div className="d-flex">
                        <span>Predicted category is</span>
                        <span className="predicted-category">
                            {data.category}
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}
export default CategoryPrediction
