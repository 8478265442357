import requests from './httpClient'

const Gcp = {
    getGcpDevices(body) {
        return requests.get('/gcpDevices')
    },
    getGcpDeviceById(id, projectId, zone) {
        return requests.get(
            `/gcpDevices/${id}?projectId=${projectId}&&zone=${zone}`
        )
    },
    getGcpStorages(body) {
        return requests.get('/gcpStorages')
    },
    getGcpStorageById(id) {
        return requests.get(`/gcpStorages/${id}`)
    },
    getGcpResources() {
        return requests.get('/gcpResources')
    },
}

export default Gcp
