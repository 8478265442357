import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AiIntelligence from '../../services/aiIntelligence.service'

const initialState = {
    data: [],
    isCatalogLoading: false,
    predictedCategory: [],
    isCatalog: false,
}

export const fetchCatalogs = createAsyncThunk(
    'smartcatalog/fetchCatalogs',
    async ({ title }, thunkAPI) => {
        try {
            if (title !== '') {
                const data = await AiIntelligence.searchCatalog({ title })
                return data
            }
            return {}
        } catch (error) {
            return thunkAPI.rejectWithValue()
        }
    }
)

export const smartCatalogs = createSlice({
    name: 'smartcatalog',
    initialState,
    extraReducers: {
        [fetchCatalogs.fulfilled]: (state, action) => {
            state.data = action.payload
            state.isCatalogLoading = false
        },
        [fetchCatalogs.pending]: (state, action) => {
            state.isCatalogLoading = true
        },
    },
})

// Action creators are generated for each case reducer function
const { reducer } = smartCatalogs

export default reducer
