import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AuthService from '../../services/auth.service'
import { clearSession } from './auth'

const initialState = {
    usedId: null,
}

export const userData = createAsyncThunk('user/userData', async (thunkAPI) => {
    return await AuthService.getUser()
        .then((response) => {
            console.log(response, 'User Response')
            return response
        })
        .catch(function (err) {
            thunkAPI.dispatch(clearSession())
            localStorage.removeItem('token')
        })
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: {
        [userData.rejected]: (state, action) => {
            state.usedId = null
        },
        [userData.pending]: (state, action) => {
            state.usedId = null
        },
        [userData.fulfilled]: (state, action) => {
            state.usedId = action.payload.id
        },
    },
})

// Action creators are generated for each case reducer function
const { reducer } = userSlice

export default reducer
