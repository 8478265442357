import React, { useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import { getInstance } from '../redux/slices/aws'
import awsService from '../services/aws.service'
import Dashboard from '../containers/Dashboard'

export function ClickableCell({ name, instanceID }) {
    const dispatch = useDispatch()

    return (
        <span
            onClick={() => dispatch(getInstance(instanceID))}
            className="me-2"
        >
            {name}
        </span>
    )
}

function AllAws() {
    const data = useSelector((state) => state.aws.allDevices)
    const instanceID = useSelector((state) => state.aws.instanceId)
    const [value, setValue] = React.useState([])
    const columns = useMemo(
        () => [
            {
                Header: 'All AWS Resources',
                columns: [
                    {
                        Header: 'Resource ID',
                        accessor: 'resourceId',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.id}
                                name={props.row.original.resourceId}
                            />
                        ),
                    },
                    {
                        Header: 'Resource Type',
                        accessor: 'resourceType',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.id}
                                name={props.row.original.resourceType}
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )

    const getDeviceData = useCallback((ID) => {
        awsService.getDeviceById(ID).then(function (response) {
            //console.log(response);
            setValue(response)
        })
    }, [])
    useEffect(() => {
        if (instanceID != null) {
            getDeviceData(instanceID)
        }
    }, [instanceID])

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue={value}
            title="All Devices"
        />
    )
}

export default AllAws
