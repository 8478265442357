import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from './message'

import AuthService from '../../services/auth.service'

const token = localStorage.getItem('token')
const initialState = token
    ? { isLoggedIn: true, token }
    : { isLoggedIn: false, token: null }

export const register = createAsyncThunk(
    'auth/register',
    async ({ username, email, password }, thunkAPI) => {
        try {
            const response = await AuthService.register({ email, password })
            thunkAPI.dispatch(setMessage(''))
            return response
        } catch (error) {
            console.log(error?.response?.data?.error)
            const message = error?.response?.data?.error
            thunkAPI.dispatch(setMessage(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

export const login = createAsyncThunk(
    'auth/login',
    async ({ email, password }, thunkAPI) => {
        try {
            const data = await AuthService.login({ email, password })
            localStorage.setItem('token', data.id)
            return { token: data.id }
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            thunkAPI.dispatch(setMessage(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

export const logout = createAsyncThunk('auth/logout', async () => {
    await AuthService.logout().then(() => {
        localStorage.removeItem('token')
    })
})

export const clearSession = createAsyncThunk('auth/clear', async () => {
    return initialState
})

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoggedIn = false
        },
        [register.rejected]: (state, action) => {
            state.isLoggedIn = false
        },
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true
            state.token = action.payload.token
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false
            state.token = null
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false
            state.token = null
        },
        [clearSession.fulfilled]: (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn
            state.token = action.payload.token
        },
    },
})

const { reducer } = authSlice
export default reducer
