import requests from './httpClient'

const AiIntelligence = {
    searchCatalog(body) {
        return requests.post('/getcatalog', body)
    },
    searchCategory(body) {
        return requests.post('/getcategory', body)
    },
}

export default AiIntelligence
