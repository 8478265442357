import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import { awsdevicelist, getAwsInstance } from '../redux/slices/aws'
import awsService from '../services/aws.service'
import Dashboard from '../containers/Dashboard'

export function ClickableCell({ name, instanceID }) {
    const dispatch = useDispatch()
    return (
        <span
            onClick={() => dispatch(getAwsInstance(instanceID))}
            className="me-2"
        >
            {name}
        </span>
    )
}

function Aws(props) {
    const data = useSelector((state) => state.aws.awsDevices)
    const instanceID = useSelector((state) => state.aws.awsinstanceId)
    const [value, setValue] = React.useState([])
    const dispatch = useDispatch()

    const initFetch = useCallback(() => {
        dispatch(awsdevicelist())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    const getDeviceData = useCallback((ID) => {
        awsService.getAWSDeviceById(ID).then(function (response) {
            setValue(response)
        })
    }, [])

    useEffect(() => {
        if (instanceID != null) {
            getDeviceData(instanceID)
        }
    }, [instanceID])

    const columns = useMemo(
        () => [
            {
                Header: 'Device',
                columns: [
                    {
                        Header: 'Instance Id',
                        accessor: 'InstanceId',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.InstanceId}
                            />
                        ),
                    },
                    {
                        Header: 'Mac Address',
                        accessor: 'NetworkInterfaces',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={
                                    props.row.original.NetworkInterfaces[0]
                                        .MacAddress
                                }
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'Details',
                columns: [
                    {
                        Header: 'InstanceType',
                        accessor: 'InstanceType',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.InstanceType}
                            />
                        ),
                    },
                    {
                        Header: 'Launch Time',
                        accessor: 'LaunchTime',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.LaunchTime}
                            />
                        ),
                    },
                    {
                        Header: 'Root Device Name',
                        accessor: 'RootDeviceName',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.RootDeviceName}
                            />
                        ),
                    },
                    {
                        Header: 'Key Name',
                        accessor: 'KeyName',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.KeyName}
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue={value}
            title="AWS EC2 Instances"
        />
    )
}

export default Aws
