import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCatalogs } from '../redux/slices/smartCatalog.js'
import CatalogCard from '../containers/CatalogCard'
function SmartCatloging() {
    const catalogState = useSelector((state) => state.smartCatalog)
    const dispatch = useDispatch()
    useEffect(() => {}, [])
    const [title, setTitle] = useState('')

    useEffect(() => {
        console.log(title)
        dispatch(fetchCatalogs({ title }))
    }, [title])

    const handleOnChange = (e) => {
        e.preventDefault()
        setTitle(e.target.value)
    }
    return (
        <div className="container">
            <div className="mt-3">
                <Link to="/allresources" className="bt-home">
                    <i className="fa fa-angle-left mx-2"></i> Back to Home
                </Link>
            </div>
            <div className="smart-search mt-5">
                <select className="form-control sc-hits">
                    <option>select</option>
                </select>
                <input
                    type="search"
                    onChange={(e) => handleOnChange(e)}
                    placeholder="search"
                    className="form-control"
                />
            </div>
            {console.log(
                typeof catalogState.data !== 'undefined',
                catalogState.data,
                catalogState
            )}
            {typeof catalogState.data !== 'undefined' ? (
                catalogState.isCatalogLoading == false ? (
                    <>
                        {catalogState.data.AllPred === undefined
                            ? 'Loading...'
                            : catalogState.data.AllPred.length != undefined &&
                              catalogState.data.AllPred.map((item) => (
                                  <CatalogCard item={item} />
                              ))}
                    </>
                ) : (
                    ''
                )
            ) : (
                <></>
            )}
        </div>
    )
}
export default SmartCatloging
