import React from 'react'
import DashboardLayout from '../components/Layout/DashboardLayout'
const modules = [
    {
        icon: '/images/green_play.png',
        name: 'Endpoint',
        type: 'Base',
        version: '1.21.2',
        license: '',
        license_status: '',
        submodules: [
            {
                name: 'NAT Devices',
                category: '',
                status: '',
                user_scope: '',
                segments: '',
                group: '',
                exceptions: 'Macintosh Manual Discover,....',
                condition: 'Device is NAT: Within the last..',
            },
        ],
    },
    {
        icon: '/images/green_play.png',
        name: 'Network',
        type: 'Base',
        version: '1.21.2',
        license: '',
        license_status: '',
        submodules: [
            {
                name: 'NAT Devices',
                category: '',
                status: '',
                user_scope: '',
                segments: '',
                group: '',
                exceptions: 'Macintosh Manual Discover,....',
                condition: 'Device is NAT: Within the last..',
            },
        ],
    },
    {
        icon: '/images/green_play.png',
        name: 'Authentication',
        type: 'Base',
        version: '1.21.2',
        license: '',
        license_status: '',
        submodules: [
            {
                name: 'NAT Devices',
                category: '',
                status: '',
                user_scope: '',
                segments: '',
                group: '',
                exceptions: 'Macintosh Manual Discover,....',
                condition: 'Device is NAT: Within the last..',
            },
        ],
    },
    {
        icon: '/images/green_play.png',
        name: 'Hybrid Cloud',
        type: 'Base',
        version: '1.21.2',
        license: '',
        license_status: '',
        submodules: [
            {
                name: 'NAT Devices',
                category: '',
                status: '',
                user_scope: '',
                segments: '',
                group: '',
                exceptions: 'Macintosh Manual Discover,....',
                condition: 'Device is NAT: Within the last..',
            },
        ],
    },
    {
        icon: '/images/green_half.png',
        name: 'Core Extensions',
        type: 'Content',
        version: '1.21.2',
        license: '',
        license_status: '',
        submodules: [
            {
                name: 'NAT Devices',
                category: '',
                status: '',
                user_scope: '',
                segments: '',
                group: '',
                exceptions: 'Macintosh Manual Discover,....',
                condition: 'Device is NAT: Within the last..',
            },
        ],
    },
    {
        icon: '/images/gray_play.png',
        name: 'Device Profile Library',
        type: 'Content',
        version: '1.21.2',
        license: '',
        license_status: '',
    },
    {
        icon: '/images/gray_play.png',
        name: 'IoT Posture Assessment Library',
        type: 'Content',
        version: '1.21.2',
        license: '',
        license_status: '',
    },
    {
        icon: '/images/green_play.png',
        name: 'NIC Vendor DB',
        type: 'Content',
        version: '1.21.2',
        license: '',
        license_status: '',
    },
    {
        icon: '/images/gray_play.png',
        name: 'Security Policy Templates',
        type: 'Content',
        version: '1.21.2',
        license: '',
        license_status: '',
    },
    {
        icon: '/images/gray_play.png',
        name: 'Windows Vulnerability DB',
        type: 'Content',
        version: '1.21.2',
        license: '',
        license_status: '',
    },
    {
        icon: '/images/gray_play.png',
        name: 'Endpoint',
        type: 'Base',
        version: '1.21.2',
        license: '',
        license_status: '',
    },
]
const Modules = () => {
    const [toggled, setToggled] = React.useState(null)
    function toggleSubmodules(name) {
        setToggled(name)
    }
    return (
        <DashboardLayout>
            <div className="policy-wrapper">
                <h1 className="modules-head">MODULES</h1>
                <p className="modules-con">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum.
                </p>
                <div className="hosts-view-header">
                    <div className="host-search">
                        <div className="searchSpace ml-0">
                            <input type="search" placeholder="search" />
                            <img
                                src="../images/Icon feather-search.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="policy-container">
                    <div className="table-responsive policy-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Version</th>
                                    <th>License</th>
                                    <th>License Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modules.map((ele) => (
                                    <>
                                        <tr
                                            className={
                                                toggled == ele.name
                                                    ? 'parent-policy1 show'
                                                    : 'parent-policy1 hide'
                                            }
                                        >
                                            <td
                                                onClick={() =>
                                                    toggleSubmodules(ele.name)
                                                }
                                                className="cur-pointer"
                                            >
                                                {ele.submodules !==
                                                    (null || undefined) && (
                                                    <span className="toggle-data">
                                                        <i className="fa-solid fa-angle-right"></i>
                                                    </span>
                                                )}
                                                <span>
                                                    <img
                                                        src={ele.icon}
                                                        alt=""
                                                    />
                                                </span>
                                                {ele.name}
                                            </td>
                                            <td>{ele.type}</td>
                                            <td>{ele.version}</td>
                                            <td>{ele.license}</td>
                                            <td>{ele.license_status}</td>
                                        </tr>
                                        {ele?.submodules?.map((policy) => (
                                            <tr
                                                className={
                                                    toggled == ele.name
                                                        ? 'sub-polices show'
                                                        : 'sub-polices hide'
                                                }
                                            >
                                                <td>{policy.name}</td>
                                                <td>{policy.category}</td>
                                                <td>{policy.group}</td>
                                                <td>{policy.exceptions}</td>
                                                <td>{policy.condition}</td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <button className="btn grn-btn">Install</button>
                        <button className="btn gray-btn">Uninstall</button>
                        <button className="btn gray-btn">Rollback</button>
                        <button className="btn gray-btn">Start</button>
                        <button className="btn gray-btn">Configure</button>
                        <button className="btn gray-btn">Test</button>
                        <button className="btn gray-btn">Help</button>
                        <button className="btn gray-btn">About</button>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Modules
