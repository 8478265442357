import requests from './httpClient'

const awsService = {
    getAllDevices() {
        return requests.get('/alldevicelist')
    },
    getDeviceById(instanceID) {
        return requests.get('/alldevicelist/' + instanceID)
    },
    getS3Devices() {
        return requests.get('/s3devicelist')
    },
    getS3DeviceById(instanceID) {
        return requests.get('/s3devicelist/' + instanceID)
    },
    getAwsDevices() {
        return requests.get('/awsdevicelist')
    },
    getAWSDeviceById(instanceID) {
        return requests.get('/awsdevicelist/' + instanceID)
    },
    getAvailableRegions() {
        return requests.get('/availableregions')
    },
}

export default awsService
