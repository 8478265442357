import React from 'react'
import DashboardLayout from '../components/Layout/DashboardLayout'
import Hosts from './Hosts'

const Dashboard = ({ apiData, columnsData, instanceValue, title }) => {
    return (
        <DashboardLayout>
            <Hosts
                apiData={apiData}
                columnsData={columnsData}
                value={instanceValue}
                title={title}
            />
        </DashboardLayout>
    )
}
export default Dashboard
