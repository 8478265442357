import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import { gcpStorageList, getGcpStorageInstance } from '../redux/slices/gcp'
import Gcp from '../services/gcp.service'
import Dashboard from '../containers/Dashboard'

export function ClickableCell({ name, id }) {
    const dispatch = useDispatch()
    return (
        <span
            onClick={() => dispatch(getGcpStorageInstance(id))}
            className="me-2"
        >
            {name == true && 'True'}
            {name == false && 'False'}
            {name}
        </span>
    )
}

function CloudStorages(props) {
    const data = useSelector((state) => state.gcp.gcpStorages)
    const instanceID = useSelector((state) => state.gcp.gcpStorageID)
    const [value, setValue] = React.useState([])
    const dispatch = useDispatch()

    const initFetch = useCallback(() => {
        dispatch(gcpStorageList())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    const getDeviceData = useCallback((ID) => {
        Gcp.getGcpStorageById(ID).then(function (response) {
            setValue(response)
        })
    }, [])

    useEffect(() => {
        if (instanceID != null) {
            getDeviceData(instanceID)
        }
    }, [instanceID])

    const columns = useMemo(
        () => [
            {
                Header: 'Device',
                columns: [
                    {
                        Header: 'Id',
                        accessor: 'Id',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.id}
                            />
                        ),
                    },
                    {
                        Header: 'Etag',
                        accessor: 'etag',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.etag}
                            />
                        ),
                    },
                    {
                        Header: 'Location Type',
                        accessor: 'locationType',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.locationType}
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'Bucket Policy Only',
                columns: [
                    {
                        Header: 'Enabled',
                        accessor: 'enabled',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={
                                    props.row.original.iamConfiguration
                                        .bucketPolicyOnly.enabled
                                }
                            />
                        ),
                    },
                    {
                        Header: 'Locked Time',
                        accessor: 'lockedTime',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={
                                    props.row.original.iamConfiguration
                                        .bucketPolicyOnly.lockedTime
                                }
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'Uniform BucketLevel Access',
                columns: [
                    {
                        Header: 'Enabled',
                        accessor: 'uniformBucketLevelAccess.enabled',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={
                                    props.row.original.iamConfiguration
                                        .uniformBucketLevelAccess.enabled
                                }
                            />
                        ),
                    },
                    {
                        Header: 'Locked Time',
                        accessor: 'uniformBucketLevelAccess.lockedTime',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={
                                    props.row.original.iamConfiguration
                                        .uniformBucketLevelAccess.lockedTime
                                }
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue={value}
            title="Cloud Storages"
        />
    )
}

export default CloudStorages
