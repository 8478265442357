import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import awsService from '../../services/aws.service'

const initialState = {
    instanceId: null,
    awsinstanceId: null,
    s3instanceId: null,
    allDevices: [],
    s3Devices: [],
    awsDevices: [],
    availableRegions: [],
}

export const devicelist = createAsyncThunk('aws/devicelist', async () => {
    const res = await awsService.getAllDevices()
    return res
})

export const awsdevicelist = createAsyncThunk('aws/awsdevicelist', async () => {
    const res = await awsService.getAwsDevices()
    return res
})

export const s3devicelist = createAsyncThunk('aws/s3devicelist', async () => {
    const res = await awsService.getS3Devices()
    return res
})

export const getInstance = createAsyncThunk('aws/id', async (id) => {
    const res = id
    return res
})

export const getAwsInstance = createAsyncThunk('aws/awsid', async (id) => {
    const res = id
    return res
})

export const getS3Instance = createAsyncThunk('aws/s3id', async (id) => {
    const res = id
    return res
})

export const getAvailableRegions = createAsyncThunk(
    'aws/availableregions',
    async (id) => {
        const res = await awsService.getAvailableRegions()
        return res
    }
)

export const awsSlice = createSlice({
    name: 'aws',
    initialState,
    extraReducers: {
        [getInstance.fulfilled]: (state, action) => {
            state.instanceId = action.payload
        },
        [getAwsInstance.fulfilled]: (state, action) => {
            state.awsinstanceId = action.payload
        },
        [getS3Instance.fulfilled]: (state, action) => {
            state.s3instanceId = action.payload
        },
        [devicelist.fulfilled]: (state, action) => {
            state.allDevices = action.payload
        },
        [s3devicelist.fulfilled]: (state, action) => {
            state.s3Devices = action.payload
        },
        [awsdevicelist.fulfilled]: (state, action) => {
            state.awsDevices = action.payload
        },
        [getAvailableRegions.fulfilled]: (state, action) => {
            state.availableRegions = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
const { reducer } = awsSlice

export default reducer
