import React, { useEffect } from 'react'
import Modal from '@mui/material/Modal'
import Checkbox from '@mui/material/Checkbox'
import PolicyForm from '../components/PolicyForm'
import { useDispatch, useSelector } from 'react-redux'
import { userPolices } from '../redux/slices/policies'
import DashboardLayout from '../components/Layout/DashboardLayout'

const Policies = () => {
    const dispatch = useDispatch()
    const polices = useSelector((state) => state.policy.userPolices)
    const [toggled, setToggled] = React.useState(null)
    const [toggled1, setToggled1] = React.useState(null)
    function toggleSubpolices(name) {
        setToggled(name)
    }
    function toggleSubpolices1(name) {
        setToggled1(name)
    }
    const [newPolicy, setNewPolicy] = React.useState(false)
    const [newPolicy1, setNewPolicy1] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [open1, setOpen1] = React.useState(false)
    const handleNew = () => setNewPolicy(true)
    const closeNew = () => setNewPolicy(false)
    const handleNew1 = () => setNewPolicy1(true)
    const closeNew1 = () => setNewPolicy1(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleOpen1 = () => setOpen1(true)
    const handleClose1 = () => setOpen1(false)

    useEffect(() => {
        if (polices.length == 0) {
            dispatch(userPolices())
        }
    }, [])

    return (
        <DashboardLayout>
            <div className="policy-wrapper">
                <div className="hosts-view-header">
                    <p className="host-title">POLICY MANAGER</p>
                    <div className="host-search">
                        <div className="searchSpace">
                            <input type="search" placeholder="search" />
                            <img
                                src="../images/Icon feather-search.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="policy-container">
                    <div className="table-responsive policy-table mw-120">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Policy Name</th>
                                    <th>Title</th>
                                    <th>Value</th>
                                    <th>Comparator</th>
                                    <th>Segments</th>
                                    <th>Type</th>
                                    <th>Operator</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {polices.map((ele) => (
                                    <>
                                        <tr
                                            className={
                                                toggled == ele.policy
                                                    ? 'parent-policy show'
                                                    : 'parent-policy hide'
                                            }
                                        >
                                            <td
                                                onClick={() =>
                                                    toggleSubpolices(ele.policy)
                                                }
                                                className="cur-pointer"
                                                colspan="8"
                                            >
                                                <span className="toggle-data">
                                                    <i className="fa-solid fa-angle-right"></i>
                                                </span>
                                                {ele.policy}
                                            </td>
                                        </tr>
                                        {ele?.subrules.map((policy) => (
                                            <tr
                                                className={
                                                    toggled == ele.policy
                                                        ? 'sub-polices show'
                                                        : 'sub-polices hide'
                                                }
                                            >
                                                <td></td>
                                                <td>{policy.title}</td>
                                                <td>{policy.value}</td>
                                                <td>{policy.comparator}</td>
                                                <td></td>
                                                <td>{policy.type}</td>
                                                <td>{policy.operator}</td>
                                                <td></td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <button className="btn grn-btn" onClick={handleNew}>
                            Add
                        </button>
                        <button className="btn gray-btn">Edit</button>
                        <button className="btn gray-btn">Categorize</button>
                        <button className="btn gray-btn">Remove</button>
                        <button className="btn gray-btn">Remove</button>
                        <button className="btn gray-btn">Move to</button>
                        <button className="btn gray-btn" onClick={handleOpen}>
                            Export
                        </button>
                        <button className="btn gray-btn">Start</button>
                        <button className="btn gray-btn">Stop</button>
                        <button className="btn grn-btn" onClick={handleOpen1}>
                            Custom{' '}
                        </button>
                        <button className="btn grn-btn">Comparison</button>
                        <button className="btn grn-btn">Help</button>
                    </div>
                </div>
                <Modal open={newPolicy} onClose={closeNew}>
                    <PolicyForm closeNew={closeNew} />
                </Modal>
                <Modal open={newPolicy1} onClose={closeNew1}>
                    <div className="modal-box new-policy">
                        <div className="modal-head-top">
                            <h1>New Policy Folder</h1>
                            <span onClick={closeNew1}>
                                <i className="fa-solid fa-close"></i>
                            </span>
                        </div>
                        <div className="mpc-head">Name</div>
                        <div className="searchSpace">
                            <input type="text" placeholder="Name" />
                        </div>
                        <p>Select where to place the folder:</p>
                        <div>
                            <img src="/images/insurance.png" alt="" />
                            Policies
                        </div>
                        <ul>
                            <li>
                                <span>
                                    <i className="fa-solid fa-angle-right"></i>
                                </span>
                                <span>
                                    <img src="/images/folder.png" alt="" />
                                </span>
                                1.1 Discover
                            </li>
                            <li>
                                <span>
                                    <i className="fa-solid fa-angle-right"></i>
                                </span>
                                <span>
                                    <img src="/images/folder.png" alt="" />
                                </span>
                                1.2 Classify_ new
                            </li>
                            <li>
                                <span>
                                    <i className="fa-solid fa-angle-right"></i>
                                </span>
                                <span>
                                    <img src="/images/folder.png" alt="" />
                                </span>
                                1.2.0 Classify
                            </li>
                        </ul>
                        <div className="policy-container1">
                            <button
                                className="btn grn-trans-btn"
                                onClick={closeNew1}
                            >
                                Cancel
                            </button>
                            <button className="btn grn-btn">Ok</button>
                        </div>
                    </div>
                </Modal>
                <Modal open={open} onClose={handleClose}>
                    <div className="modal-box">
                        <div className="modal-head-top">
                            <h1>Policy: 1.1.1 Enterprise Discover</h1>
                            <span onClick={handleClose}>
                                <i className="fa-solid fa-close"></i>
                            </span>
                        </div>
                        <div className="mpc-head">Name</div>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table className="no-border">
                                    <tr>
                                        <td>Name</td>
                                        <td>1.1.1 Enterprise Discover</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>None</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Edit</button>
                            </div>
                        </div>
                        <div className="mpc-head">Scope</div>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table className="no-border">
                                    <tr>
                                        <td>IP Ranges</td>
                                        <td>All IPv6, All IPv4</td>
                                    </tr>
                                    <tr>
                                        <td>Filter by Group</td>
                                        <td>None</td>
                                    </tr>
                                    <tr>
                                        <td>Exceptions</td>
                                        <td>None</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Edit</button>
                            </div>
                        </div>
                        <div className="mpc-head">Main Rule</div>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table>
                                    <tr className="bbtm">
                                        <td>
                                            <b>Conditions</b>
                                        </td>
                                        <td>
                                            <b>Actions</b>
                                        </td>
                                        <td>
                                            <b>Re-check Matched</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>No Condition</td>
                                        <td></td>
                                        <td>Every 8 hours</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Add</button>
                            </div>
                        </div>
                        <div className="mpc-head">Sub - Rule</div>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table>
                                    <tr className="bbtm">
                                        <td>
                                            <b>Name</b>
                                        </td>
                                        <td>
                                            <b>Condition</b>
                                        </td>
                                        <td>
                                            <b>Actions</b>
                                        </td>
                                        <td>
                                            <b>Exceptions</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                    <tr>
                                        <td>NAT Devices</td>
                                        <td>NAT Devices</td>
                                        <td></td>
                                        <td>Macintosh</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Add</button>
                                <button className="btn gray-btn">Edit</button>
                                <button className="btn gray-btn">Remove</button>
                                <button className="btn gray-btn">
                                    Duplicate
                                </button>
                                <button className="btn gray-btn">Up</button>
                                <button className="btn gray-btn">Down</button>
                            </div>
                        </div>
                        <div className="policy-container1">
                            <button
                                className="btn grn-trans-btn"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button className="btn grn-btn">Ok</button>
                        </div>
                    </div>
                </Modal>
                <Modal open={open1} onClose={handleClose1}>
                    <div className="modal-box">
                        <div className="modal-head-top">
                            <h1>Policy: 1.1.1 Enterprise Discover</h1>
                            <span onClick={handleClose1}>
                                <i className="fa-solid fa-close"></i>
                            </span>
                        </div>
                        <div className="mpc-head">Name</div>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table className="no-border">
                                    <tr>
                                        <td>Name</td>
                                        <td>1.1.1 Enterprise Discover</td>
                                    </tr>
                                    <tr>
                                        <td>Description</td>
                                        <td>None</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Edit</button>
                            </div>
                        </div>
                        <div className="mpc-head">Condition</div>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <div className="mpc-text">
                                    A host matches this rule of it meets the
                                    following condition
                                </div>
                                <div className="mpc-text">
                                    One criterion is true
                                </div>
                            </div>
                            <div>
                                <button className="btn grn-btn">Edit</button>
                            </div>
                        </div>

                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table>
                                    <tr className="bbtm">
                                        <td>Criteria</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Device is NAT - Within the last 1
                                            week
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Function - Information Technology >
                                            Networking > NAT
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Member of Group - NAT Devices Manual
                                            Discover
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Add</button>
                                <button className="btn gray-btn">Edit</button>
                                <button className="btn gray-btn">Remove</button>
                            </div>
                        </div>
                        <div className="mpc-head">Actions</div>
                        <p className="mpc-text">
                            Actions are applied to hosts matching the above
                            condition.
                        </p>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table>
                                    <tr className="bbtm">
                                        <td>
                                            <b>Ena</b>
                                        </td>
                                        <td>
                                            <b>Actions</b>
                                        </td>
                                        <td>
                                            <b>Details</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Checkbox
                                                type="checkbox"
                                                checked="checked"
                                            />
                                        </td>
                                        <td>Add to Group</td>
                                        <td>Add to Group . S...</td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Add</button>
                                <button className="btn gray-btn">Edit</button>
                                <button className="btn gray-btn">Remove</button>
                            </div>
                        </div>
                        <div className="mpc-head">Advanced</div>
                        <div className="policy-container">
                            <div className="table-responsive policy-table mw-120">
                                <table className="no-border">
                                    <tr>
                                        <td>Recheck match</td>
                                        <td>Every 8 hours, All admissions</td>
                                    </tr>
                                    <tr>
                                        <td>Exceptions</td>
                                        <td>
                                            Macintosh Manual Discover,Printers
                                            Manual Discover...
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <button className="btn grn-btn">Edit</button>
                            </div>
                        </div>
                        <div className="policy-container1">
                            <button className="btn grn-trans-btn">
                                Cancel
                            </button>
                            <button className="btn grn-btn">Help</button>
                            <button className="btn grn-btn">Ok</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </DashboardLayout>
    )
}

export default Policies
