const ControlSection = ({ data }) => {
    return (
        <div className="row">
            {Object.entries(data).map(([key, val]) => {
                if (
                    typeof val === 'number' ||
                    typeof val === 'string' ||
                    typeof val === 'boolean'
                ) {
                    return (
                        <div className="col-6 host-profile-info" key={key}>
                            <p>
                                <strong>{key}</strong>:
                            </p>
                            <p>{val.toString()}</p>
                        </div>
                    )
                } else if (typeof val === 'object' && val === null) {
                    //return
                    return (
                        <div className="host-profile-info" key={key}>
                            <p>
                                <strong>{key}</strong>:
                            </p>
                            <p>Null</p>
                        </div>
                    )
                } else if (
                    typeof val === 'object' &&
                    Array.isArray(val) === false
                ) {
                    //return
                    return (
                        <div className="general">
                            <div className="general-title">
                                <p>{key}:</p>
                            </div>
                            <ControlSection data={val} />
                        </div>
                    )
                } else if (
                    typeof val === 'object' &&
                    Array.isArray(val) === true
                ) {
                    return (
                        <div className="general">
                            <div className="general-title">
                                <p>{key}:</p>
                            </div>
                            {val.map((value, idx) => (
                                <div className="general">
                                    <div className="general-title">
                                        <p key={idx}>Item {idx}:</p>
                                    </div>
                                    <ControlSection data={value} />
                                </div>
                            ))}
                        </div>
                    )
                } else {
                    return (
                        <p key={key}>
                            <strong>{key}</strong>: {typeof val} :{' '}
                            {Array.isArray(val).toString()}
                        </p>
                    )
                }
            })}
        </div>
    )
}

export default ControlSection
