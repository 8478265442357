import requests from './httpClient'

const policyService = {
    getTemplatePolicies() {
        return requests.get('/policies')
    },
    getUserPolicies() {
        return requests.get('/userpolicies')
    },
    addUserPolicies(body) {
        return requests.post('/userpolicies', body)
    },
}

export default policyService
