import { awsdevicelist, s3devicelist } from '../../redux/slices/aws'

export const FilterMenu = [
    {
        title: 'All',
        path: '/about-us',
        icon: 'reply-all.svg',
        subNav: [],
    },
    {
        title: 'Segments',
        icon: 'segmentation.svg',
        subNav: [
            {
                title: 'AWS',
                cName: 'sub-nav',
                subNav: [
                    {
                        title: 'EC2 Instances',
                        path: '/awsdevicelist',
                        cName: 'sub-nav',
                        apiThunk: awsdevicelist(),
                    },
                    {
                        title: 'S3 Devices',
                        path: '/s3aws',
                        cName: 'sub-nav',
                        apiThunk: s3devicelist(),
                    },
                ],
            },
            {
                title: 'GCP',
                cName: 'sub-nav',
                subNav: [
                    {
                        title: 'Compute Engines',
                        path: '/compute-engines',
                        cName: 'sub-nav',
                    },
                    {
                        title: 'Cloud Storages',
                        path: '/cloud-storages',
                        cName: 'sub-nav',
                    },
                    {
                        title: 'Resources',
                        path: '/gcp-resources',
                        cName: 'sub-nav',
                    },
                ],
            },
            {
                title: 'Azure',
                cName: 'sub-nav',
                subNav: [
                    {
                        title: 'Virtual Machines',
                        path: '/virtual-machines',
                        cName: 'sub-nav',
                    },
                    {
                        title: 'Storage Accounts',
                        path: '/storage-accounts',
                        cName: 'sub-nav',
                    },
                    {
                        title: 'Resources',
                        path: '/azure-resources',
                        cName: 'sub-nav',
                    },
                ],
            },
        ],
    },
    {
        title: 'Organizational Units',
        path: '/organizational-units',
        icon: 'organization.svg',
        subNav: [],
    },
    {
        title: 'Default Groups',
        path: '/default-groups',
        icon: 'Icon-ionic-ios-folder.svg',
        subNav: [],
    },
    {
        title: 'Groups',
        path: '/groups',
        icon: 'Icon-ionic-ios-folder.svg',
        subNav: [],
    },
]
