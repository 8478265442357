import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

const SidebarLink = styled(Link)`
    display: flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    list-style: none;
    text-decoration: none;
    font-size: 14px;

    &:hover {
        background: #0cb792;
        border-left: 4px solid #0cb792;
        cursor: pointer;

        svg {
            color: #fff;
        }

        span {
            color: #fff;
        }
    }
`

const SidebarLabel = styled.span`
    margin-left: 8px;

    &:hover {
        color: #fff;
    }
`

const DropdownLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 14px;
    padding: 10px 10px 10px 35px;

    &:hover {
        background: #0cb792;
        cursor: pointer;
        color: #fff;

        svg,
        span {
            color: #fff;
        }
    }
`

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false)

    const showSubnav = () => setSubnav(!subnav)

    return (
        <>
            <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                        ? item.iconClosed
                        : null}
                </div>
            </SidebarLink>
            {subnav && (
                <div className={`sub-menu ${subnav ? 'sn-show' : 'sn-hidden'}`}>
                    {item.subNav.map((item, index) => {
                        return (
                            <DropdownLink to={item.path} key={index}>
                                {item.icon}
                                <SidebarLabel>{item.title}</SidebarLabel>
                            </DropdownLink>
                        )
                    })}
                </div>
            )}
        </>
    )
}

export default SubMenu
