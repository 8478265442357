import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#35aa70',
            contrastText: '#fff',
        },
        secondary: {
            main: '#e2e8f0',
        },
    },
    typography: {
        fontFamily: "'Poppins', sans-serif ",
    },
})

export default theme
