import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './redux'
import RoutesLayout from './routes'
import Layout from './components/Layout'
import theme from './theme'
import './App.css'

function App(props) {
    return (
        <BrowserRouter>
            <div className="App">
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ThemeProvider theme={theme}>
                            <Layout>
                                <RoutesLayout />
                            </Layout>
                        </ThemeProvider>
                    </PersistGate>
                </Provider>
            </div>
        </BrowserRouter>
    )
}

export default App
