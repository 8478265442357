import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Gcp from '../../services/gcp.service'

const initialState = {
    gcpDevices: [],
    gcpInstance: {},
    gcpStorages: [],
    gcpStorageID: null,
    gcpResources: [],
}

export const gcpDeviceList = createAsyncThunk('gcp/devicelist', async () => {
    const res = await Gcp.getGcpDevices()
    return res
})
export const getGcpInstance = createAsyncThunk(
    'gcp/gcpid',
    async ({ id, zone }) => {
        const res = { id, zone }
        console.log(res)
        return res
    }
)

export const gcpStorageList = createAsyncThunk('gcp/storagelist', async () => {
    const res = await Gcp.getGcpStorages()
    return res
})
export const getGcpStorageInstance = createAsyncThunk(
    'gcp/storageid',
    async (id) => {
        const res = id
        return res
    }
)

export const gcpResourcesList = createAsyncThunk(
    'gcp/resourcelist',
    async () => {
        const res = await Gcp.getGcpResources()
        return res
    }
)

export const gcpSlice = createSlice({
    name: 'gcp',
    initialState,
    extraReducers: {
        [gcpDeviceList.fulfilled]: (state, action) => {
            state.gcpDevices = action.payload
        },
        [getGcpInstance.fulfilled]: (state, action) => {
            state.gcpInstance = action.payload
        },
        [gcpStorageList.fulfilled]: (state, action) => {
            state.gcpStorages = action.payload
        },
        [getGcpStorageInstance.fulfilled]: (state, action) => {
            state.gcpStorageID = action.payload
        },
        [gcpResourcesList.fulfilled]: (state, action) => {
            state.gcpResources = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
const { reducer } = gcpSlice

export default reducer
