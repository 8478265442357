import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../common/Navbar'
import { userData } from '../../redux/slices/userSlice'
import '../style/common.css'

const Layout = ({ children, props }) => {
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.user.usedId)
    let navigate = useNavigate()

    useEffect(() => {
        if (userId == (null || undefined)) {
            dispatch(userData())
            navigate('/login')
        }
    }, [userId])

    return (
        <div className="main-container">
            {userId != (null || undefined) && <Navbar />}
            {children}
        </div>
    )
}

export default Layout
