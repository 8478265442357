import React, { useState, useEffect, useCallback } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { TabPanel } from '../components/common/cd-tabpanel'
import ControlSection from '../utils/control-section'
import { a11yProps } from '../utils/constants'

function DeviceInfo({ data }) {
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div className="host-profiles">
            <div className="host-tabs">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Profile" {...a11yProps(0)} />
                    <Tab label="Compliance" {...a11yProps(1)} />
                    <Tab label="All Policy" {...a11yProps(2)} />
                </Tabs>
            </div>
            <TabPanel value={value} index={0}>
                {data && <ControlSection data={data} />}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {data && <ControlSection data={data} />}
            </TabPanel>
            <TabPanel value={value} index={2}></TabPanel>
        </div>
    )
}

export default DeviceInfo
