import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist'
import userReducer from './slices/userSlice'
import authReducer from './slices/auth'
import messageReducer from './slices/message'
import awsReducer from './slices/aws'
import policyReducer from './slices/policies'
import smartCatalogs from './slices/smartCatalog'
import gcpReducer from './slices/gcp'
import azureReducer from './slices/azure'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    auth: authReducer,
    message: messageReducer,
    user: userReducer,
    aws: awsReducer,
    policy: policyReducer,
    smartCatalog: smartCatalogs,
    gcp: gcpReducer,
    azure: azureReducer,
})

const resettableRootReducer = (state, action) => {
    if (action.type == 'auth/logout/fulfilled') {
        return rootReducer(undefined, action)
    }
    return rootReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, resettableRootReducer)

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    // middleware option needs to be provided for avoiding the error. ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
})

export const persistor = persistStore(store)
export default store
