import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { login } from '../redux/slices/auth'
import { userData } from '../redux/slices/userSlice'
import { devicelist, getAvailableRegions } from '../redux/slices/aws'
import { clearMessage } from '../redux/slices/message'

const Login = () => {
    let navigate = useNavigate()
    const userToken = localStorage.getItem('token')

    const [loading, setLoading] = useState(false)
    const { message } = useSelector((state) => state.message)

    const dispatch = useDispatch()

    useEffect(() => {
        if (userToken != (null || undefined)) {
            navigate('/allresources')
        }
    }, [userToken, navigate])

    useEffect(() => {
        dispatch(clearMessage())
    }, [dispatch])

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string('Enter your email')
                .email('Enter a valid email')
                .required('Email is required'),
            password: Yup.string('Enter your password').required(
                'Password is required'
            ),
        }),
        onSubmit: (values) => {
            const { email, password } = values
            setLoading(true)
            dispatch(login({ email, password }))
                .unwrap()
                .then(() => {
                    navigate('/allresources')
                    dispatch(userData())
                    dispatch(devicelist())
                    dispatch(getAvailableRegions())
                })
                .catch(() => {
                    setLoading(false)
                })
        },
    })

    return (
        <div className="auth-form">
            <div className="card card-container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="text-center mb-3">
                        <h4>Sign in with</h4>

                        <div
                            className="d-flex justify-content-between mx-auto social-icons"
                            style={{ width: '40%' }}
                        >
                            <span className="si-link">
                                <i className="fa-brands fa-facebook"></i>
                            </span>
                            <span className="si-link">
                                <i className="fa-brands fa-twitter"></i>
                            </span>
                            <span className="si-link">
                                <i className="fa-brands fa-google"></i>
                            </span>
                            <span className="si-link">
                                <i className="fa-brands fa-github"></i>
                            </span>
                        </div>

                        <div className="clearfix or-text">
                            <span>Or</span>
                        </div>
                    </div>

                    {message && <Alert severity="error">{message}</Alert>}

                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        size="normal"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        size="normal"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.password)}
                        helperText={formik.errors.password}
                        margin="normal"
                        fullWidth
                    />
                    <div className="login-bottom">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Remember me"
                        />
                        <a href="!#">Forgot password?</a>
                    </div>

                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        sx={{ margin: '15px auto' }}
                        disabled={!formik.dirty}
                        type="submit"
                    >
                        {loading && (
                            <span className="fa fa-spin fa-spinner"></span>
                        )}
                        <span>Login</span>
                    </Button>
                    <p className="text-center">
                        Don't have an account?{' '}
                        <Link to="/register">Register</Link>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default Login
