import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import { logout } from '../../redux/slices/auth'
import Button from '@mui/material/Button'
import { SidebarData } from './SidebarData'
import SubMenu from './SubMenu'
import { Link } from 'react-router-dom'

const navItems = ['Home', 'Assets Inventory', 'Policy', 'Dashboard']
export default function NavBar(props) {
    let navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
    const dispatch = useDispatch()

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const logMeOut = () => {
        dispatch(logout())
        handleMenuClose()
        navigate('/login')
    }

    const [anchorEl1, setAnchorEl1] = useState(null)
    const open = Boolean(anchorEl1)
    const handleClick = (event) => {
        setAnchorEl1(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl1(null)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem onClick={logMeOut}>Logout</MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <i className="fa fa-bars" />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Menu>
    )

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>
                    <Box className="complaint-dashbrd-header">
                        <nav>
                            <ul>
                                <li>File</li>
                                <li>Reports</li>
                                <li>Actions</li>
                                <li>Tools</li>
                                <li>Logs</li>
                                <li>Display</li>
                                <li>Help</li>
                                <li>
                                    <div>
                                        <Button
                                            type="button"
                                            className="menu-link"
                                            aria-controls={
                                                open ? 'ai-menu' : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                open ? 'true' : undefined
                                            }
                                            onClick={handleClick}
                                            id="ai-button"
                                        >
                                            AI Intelligence
                                        </Button>
                                        {open !== undefined ? (
                                            <Menu
                                                id="ai-menu"
                                                aria-labelledby="ai-button"
                                                anchorEl={anchorEl1}
                                                open={anchorEl1}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby':
                                                        'ai-button',
                                                }}
                                            >
                                                <MenuItem onClick={handleClose}>
                                                    <Link
                                                        to="/category-prediction"
                                                        className="ai-intelle"
                                                    >
                                                        Category prediction
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={handleClose}
                                                    className="ai-intelle"
                                                >
                                                    <Link to="/smart-catloging">
                                                        Smart cataloging
                                                    </Link>
                                                </MenuItem>
                                            </Menu>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </Box>
                    <Box className="right-menu">
                        <nav className="top-right-menu">
                            <ul className="list-inline">
                                {SidebarData.map((item, index) => {
                                    return (
                                        <li
                                            className="list-inline-item"
                                            key={index}
                                        >
                                            <SubMenu item={item} />
                                        </li>
                                    )
                                })}
                                <li className="list-inline-item">
                                    <span
                                        onClick={logMeOut}
                                        className="logout-link"
                                    >
                                        Logout
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </Box>
                </Toolbar>
            </AppBar>

            {renderMobileMenu}
            {renderMenu}
        </Box>
    )
}
