import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import {
    azureResourcesList,
    getAzureResourceInstance,
} from '../redux/slices/azure'
import Azure from '../services/azure.service'
import Dashboard from '../containers/Dashboard'

export function ClickableCell({ title, id, type }) {
    const dispatch = useDispatch()
    return (
        <span
            onClick={() => dispatch(getAzureResourceInstance({ id, type }))}
            className="me-2"
        >
            {title}
        </span>
    )
}

function AzureResources(props) {
    const data = useSelector((state) => state.azure.azureResources)
    const instanceID = useSelector((state) => state.azure.azureResourceID)
    const [value, setValue] = React.useState([])
    const dispatch = useDispatch()

    const initFetch = useCallback(() => {
        dispatch(azureResourcesList())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    const getDeviceData = useCallback((azureInstance) => {
        console.log(azureInstance)
        Azure.getAzureResourceById(azureInstance).then(function (response) {
            setValue(response)
        })
    }, [])

    useEffect(() => {
        if (instanceID != null) {
            getDeviceData(instanceID)
        }
    }, [instanceID])

    const columns = useMemo(
        () => [
            {
                Header: 'Device',
                columns: [
                    {
                        Header: 'Id',
                        accessor: 'id',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                type={props.row.original.type}
                                title={props.row.original.id}
                            />
                        ),
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                type={props.row.original.type}
                                title={props.row.original.type}
                            />
                        ),
                    },
                    {
                        Header: 'Location',
                        accessor: 'location',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                type={props.row.original.type}
                                title={props.row.original.location}
                            />
                        ),
                    },
                    {
                        Header: 'Type',
                        accessor: 'type',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                type={props.row.original.type}
                                title={props.row.original.type}
                            />
                        ),
                    },
                    {
                        Header: 'Creation Time',
                        accessor: 'creation_time',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                type={props.row.original.type}
                                title={props.row.original.creation_time}
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'sku',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'sku.name',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                type={props.row.original.type}
                                title={props.row.original.sku.name}
                            />
                        ),
                    },
                    {
                        Header: 'Tier',
                        accessor: 'sku.tier',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                type={props.row.original.type}
                                title={props.row.original.sku.tier}
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue={value}
            title="Cloud Storages"
        />
    )
}

export default AzureResources
