import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import { gcpDeviceList, getGcpInstance } from '../redux/slices/gcp'
import Gcp from '../services/gcp.service'
import Dashboard from '../containers/Dashboard'

export function ClickableCell({ name, id, zone }) {
    const dispatch = useDispatch()
    return (
        <span
            onClick={() => dispatch(getGcpInstance({ id, zone }))}
            className="me-2"
        >
            {name}
        </span>
    )
}

function ComputeEngines(props) {
    const data = useSelector((state) => state.gcp.gcpDevices)
    const gcpInstance = useSelector((state) => state.gcp.gcpInstance)
    const [value, setValue] = React.useState([])
    const dispatch = useDispatch()

    const initFetch = useCallback(() => {
        dispatch(gcpDeviceList())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    const getDeviceData = useCallback((gcpInstance) => {
        var url_string = gcpInstance?.zone
        console.log(gcpInstance)
        if (url_string != undefined) {
            var url = new URL(url_string)
            var projectId = url.pathname.split('/')[4]
            Gcp.getGcpDeviceById(
                gcpInstance.id,
                projectId,
                gcpInstance.zone
            ).then(function (response) {
                setValue(response)
            })
        }
    }, [])

    useEffect(() => {
        if (gcpInstance != null) {
            getDeviceData(gcpInstance)
        }
    }, [gcpInstance])

    const columns = useMemo(
        () => [
            {
                Header: 'Device',
                columns: [
                    {
                        Header: 'Id',
                        accessor: 'Id',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.id}
                                zone={props.row.original.zone}
                            />
                        ),
                    },
                    {
                        Header: 'CPU Platform',
                        accessor: 'cpuPlatform',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                zone={props.row.original.zone}
                                name={props.row.original.cpuPlatform}
                            />
                        ),
                    },
                    {
                        Header: 'Timestamp',
                        accessor: 'creationTimestamp',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                zone={props.row.original.zone}
                                name={props.row.original.creationTimestamp}
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'Disks',
                columns: [
                    {
                        Header: 'Architecture',
                        accessor: 'architecture',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                zone={props.row.original.zone}
                                name={props.row.original.disks[0].architecture}
                            />
                        ),
                    },
                    {
                        Header: 'Device Name',
                        accessor: 'deviceName',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                zone={props.row.original.zone}
                                name={props.row.original.disks[0].deviceName}
                            />
                        ),
                    },
                    {
                        Header: 'Type',
                        accessor: 'type',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                zone={props.row.original.zone}
                                name={props.row.original.disks[0].type}
                            />
                        ),
                    },
                    {
                        Header: 'Mode',
                        accessor: 'mode',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                zone={props.row.original.zone}
                                name={props.row.original.disks[0].mode}
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue={value}
            title="Compute Engines"
        />
    )
}

export default ComputeEngines
