import axios from 'axios'
import { baseURL } from '../utils/constants'

const instance = axios.create({
    baseURL,
    withCredentials: true,
    method: 'cors',
})

instance.interceptors.request.use(
    function (config) {
        config.headers.withCredentials = true
        return config
    },
    function (err) {
        return Promise.reject(err)
    }
)
const responseBody = (response) => response.data

const requests = {
    get: (url, body, headers) =>
        instance
            .get(url, body, headers)
            .then(responseBody)
            .catch((error) => {
                if (error.response.status === 401) {
                    console.log(error)
                    console.log('removeign local token')
                    localStorage.removeItem('persist:root')
                } else {
                    console.log('Hey I am in else')
                }
            }),

    post: (url, body) =>
        instance
            .post(url, body)
            .then(responseBody)
            .catch((error) => {
                console.log(error)
            }),

    put: (url, body, headers) =>
        instance
            .put(url, body, headers)
            .then(responseBody)
            .catch((error) => {
                console.log(error)
            }),

    patch: (url, body) =>
        instance
            .patch(url, body)
            .then(responseBody)
            .catch((error) => {
                console.log(error)
            }),

    delete: (url) =>
        instance
            .delete(url)
            .then(responseBody)
            .catch((error) => {
                console.log(error)
            }),
}

export default requests
