import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import Hosts from '../../containers/Hosts'
import { awsdevicelist, getAwsInstance } from '../../redux/slices/aws'
import awsService from '../../services/aws.service'

export function ClickableCell({ name, instanceID }) {
    const dispatch = useDispatch()
    return (
        <span
            onClick={() => dispatch(getAwsInstance(instanceID))}
            className="me-2"
        >
            {name}
        </span>
    )
}

const SettingsModal = ({ closeNew }) => {
    const dispatch = useDispatch()
    const data = [
        {
            title: 'Cybersoftware Plugin',
            submenu: [{ name: 'CSP1' }, { name: 'CSP2' }],
        },
        {
            title: 'Modules',
            submenu: [
                { name: 'azure' },
                { name: 'aws' },
                { name: 'gcp' },
                { name: 'Auction AI' },
            ],
        },
    ]
    const [value, setValue] = React.useState([])
    const apiData = useSelector((state) => state.aws.awsDevices)
    const instanceID = useSelector((state) => state.aws.awsinstanceId)
    const initFetch = useCallback(() => {
        dispatch(awsdevicelist())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])
    const columns = useMemo(
        () => [
            {
                Header: 'Device',
                columns: [
                    {
                        Header: 'Instance Id',
                        accessor: 'InstanceId',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.InstanceId}
                            />
                        ),
                    },
                    {
                        Header: 'Mac Address',
                        accessor: 'NetworkInterfaces',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={
                                    props.row.original.NetworkInterfaces[0]
                                        .MacAddress
                                }
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'Details',
                columns: [
                    {
                        Header: 'InstanceType',
                        accessor: 'InstanceType',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.InstanceType}
                            />
                        ),
                    },
                    {
                        Header: 'Launch Time',
                        accessor: 'LaunchTime',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.LaunchTime}
                            />
                        ),
                    },
                    {
                        Header: 'Root Device Name',
                        accessor: 'RootDeviceName',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.RootDeviceName}
                            />
                        ),
                    },
                    {
                        Header: 'Key Name',
                        accessor: 'KeyName',
                        Cell: (props) => (
                            <ClickableCell
                                instanceID={props.row.original.InstanceId}
                                name={props.row.original.KeyName}
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )
    const getDeviceData = useCallback((ID) => {
        awsService.getAWSDeviceById(ID).then(function (response) {
            setValue(response)
        })
    }, [])
    useEffect(() => {
        if (instanceID != null) {
            getDeviceData(instanceID)
        }
    }, [instanceID])
    return (
        <div className="modal-box settings-modal">
            <div className="modal-head-top">
                <h1>Settings</h1>
                <span onClick={closeNew}>
                    <i className="fa-solid fa-close"></i>
                </span>
            </div>
            <div className="row mt-4">
                <div className="col-md-3">
                    <h6>Options</h6>
                    <div class="host-search">
                        <div class="searchSpace mb-4 mt-2">
                            <input type="search" placeholder="search" />
                        </div>
                    </div>
                    {data.map((item) => (
                        <TreeView
                            aria-label="file system navigator"
                            defaultCollapseIcon={
                                <i className="fa fa-angle-down" />
                            }
                            defaultExpandIcon={
                                <i className="fa fa-angle-right" />
                            }
                        >
                            <TreeItem nodeId={item.title} label={item.title}>
                                {item.submenu.map((data) => (
                                    <TreeItem
                                        nodeId={data.name}
                                        label={data.name}
                                    ></TreeItem>
                                ))}
                            </TreeItem>
                        </TreeView>
                    ))}
                </div>
                <div className="col-md-9">
                    <Hosts
                        apiData={apiData}
                        columnsData={columns}
                        value={value}
                        title="AWS EC2 Instances"
                    />
                </div>
            </div>
        </div>
    )
}

export default SettingsModal
