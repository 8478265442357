import React from 'react'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const NameForm = ({ name, closeNew, handleNext, handlePrev }) => {
    const formik = useFormik({
        initialValues: {
            name,
            description: '',
        },
        validationSchema: Yup.object({
            name: Yup.string('Enter your name').required(),
            description: Yup.string('Enter your description'),
        }),
        onSubmit: (values) => {
            console.log(values)
            handleNext()
        },
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
                <h4>Name of the policy</h4>
                <p>Enter name and description of policy</p>
            </div>

            <TextField
                id="name"
                name="name"
                label="Name"
                type="text"
                size="normal"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name}
                margin="normal"
                fullWidth
            />
            <TextField
                id="description"
                name="description"
                label="Description"
                type="text"
                size="normal"
                variant="outlined"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.description)}
                helperText={formik.errors.description}
                margin="normal"
                fullWidth
                multiline
                rows={4}
            />

            <div className="policy-container1">
                <button className="btn grn-btn" onClick={handlePrev}>
                    Back
                </button>
                <button
                    type="submit"
                    className="btn grn-btn"
                    onClick={handleNext}
                >
                    Next
                </button>
                <button className="btn grn-trans-btn" onClick={closeNew}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default NameForm
