import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import { azureDeviceList, getAzureInstance } from '../redux/slices/azure'
import Azure from '../services/azure.service'
import Dashboard from '../containers/Dashboard'

export function ClickableCell({ name, id, title }) {
    const dispatch = useDispatch()
    return (
        <span
            onClick={() => dispatch(getAzureInstance({ name, id }))}
            className="me-2"
        >
            {title}
        </span>
    )
}

function VirtualMachines(props) {
    const data = useSelector((state) => state.azure.azureDevices)
    const azureInstance = useSelector((state) => state.azure.azureInstance)
    const [value, setValue] = React.useState([])
    const dispatch = useDispatch()

    const initFetch = useCallback(() => {
        dispatch(azureDeviceList())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    const getDeviceData = useCallback((azureInstance) => {
        var url_string = azureInstance.id
        if (url_string != undefined) {
            var id = url_string.split('/')[4]
            console.log(id)
            Azure.getAzureDeviceById(id, azureInstance.name).then(function (
                response
            ) {
                setValue(response)
            })
        }
    }, [])

    useEffect(() => {
        if (azureInstance != null) {
            getDeviceData(azureInstance)
        }
    }, [azureInstance])

    const columns = useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (props) => (
                    <ClickableCell
                        id={props.row.original.id}
                        name={props.row.original.name}
                        title={props.row.original.id}
                    />
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (props) => (
                    <ClickableCell
                        id={props.row.original.id}
                        name={props.row.original.name}
                        title={props.row.original.name}
                    />
                ),
            },
            {
                Header: 'Location',
                accessor: 'location',
                Cell: (props) => (
                    <ClickableCell
                        id={props.row.original.id}
                        name={props.row.original.name}
                        title={props.row.original.location}
                    />
                ),
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: (props) => (
                    <ClickableCell
                        id={props.row.original.id}
                        name={props.row.original.name}
                        title={props.row.original.type}
                    />
                ),
            },
            {
                Header: 'Provisioning State',
                accessor: 'provisioning_state',
                Cell: (props) => (
                    <ClickableCell
                        id={props.row.original.id}
                        name={props.row.original.name}
                        title={props.row.original.provisioning_state}
                    />
                ),
            },
        ],
        []
    )

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue={value}
            title="Virtual Machines"
        />
    )
}

export default VirtualMachines
