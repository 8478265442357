import requests from './httpClient'

const authService = {
    login(body) {
        return requests.post('/login', body)
    },
    register(body) {
        console.log(body)
        return requests.post('/register', body)
    },
    logout() {
        return requests.post('/logout')
    },
    getUser() {
        return requests.get('/@me')
    },
}

export default authService
