import React from 'react'
import { Resizable } from 're-resizable'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Table from './Table'
import '../containers/Table.css'
import AllDeviceInfo from '../views/AllDeviceInfo'
const style = {
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    maxWidth: '100vw',
    overflow: 'auto',
}

function Hosts({ title, apiData, columnsData, value }) {
    return (
        <div className="hosts-view">
            <div className="hosts-view-header">
                <p className="host-title">{title}</p>
                <div className="host-search">
                    <div className="searchSpace">
                        <input type="search" placeholder="search" />
                        <img src="../images/Icon-feather-search.svg" alt="" />
                    </div>
                    <div className="host-dropdown">
                        <select>
                            <option>Online/Offline</option>
                        </select>
                    </div>
                    <div className="host-checkbox">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Show Unassigned only"
                        />
                    </div>
                </div>
            </div>
            <Resizable
                style={style}
                defaultSize={{
                    width: '100%',
                    height: '45vh',
                }}
                maxWidth="100%"
                minWidth="100%"
                enable={{
                    top: false,
                    right: false,
                    bottom: true,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                }}
            >
                <div className="host-table table-responsive">
                    {apiData && <Table columns={columnsData} data={apiData} />}
                </div>
            </Resizable>
            <Resizable
                style={style}
                defaultSize={{
                    width: '100%',
                    height: '45vh',
                }}
                maxWidth="100%"
                minWidth="100%"
                enable={{
                    top: false,
                    right: false,
                    bottom: true,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                }}
            >
                {value != [] && <AllDeviceInfo data={value} />}
            </Resizable>
        </div>
    )
}

export default Hosts
