import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setMessage } from './message'
import policyService from '../../services/policy.service'

export const addPolicy = createAsyncThunk(
    'policy/add',
    async ({ policyid, policyname, value, operator }, thunkAPI) => {
        try {
            const data = await policyService.addUserPolicies({
                policyid,
                policyname,
                value,
                operator,
            })
            return data
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            thunkAPI.dispatch(setMessage(message))
            return thunkAPI.rejectWithValue()
        }
    }
)

export const userPolices = createAsyncThunk('policy/list', async () => {
    const res = await policyService.getUserPolicies()
    return res
})

export const clearNote = createAsyncThunk('policy/clear', async () => {
    return { note: '' }
})

const initialState = {
    userPolices: [],
    note: '',
}

const policySlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: {
        [addPolicy.fulfilled]: (state, action) => {
            state.note = 'Policy added successfully'
        },
        [userPolices.fulfilled]: (state, action) => {
            state.userPolices = action.payload
        },
        [clearNote.fulfilled]: (state, action) => {
            state.note = action.payload.note
        },
    },
})

const { reducer } = policySlice
export default reducer
