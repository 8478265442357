import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import {
    azureStorageList,
    getAzureStorageInstance,
} from '../redux/slices/azure'
import Azure from '../services/azure.service'
import Dashboard from '../containers/Dashboard'

export function ClickableCell({ title, name, id }) {
    const dispatch = useDispatch()
    return (
        <span
            onClick={() => dispatch(getAzureStorageInstance({ id, name }))}
            className="me-2"
        >
            {title}
        </span>
    )
}

function StorageAccounts(props) {
    const data = useSelector((state) => state.azure.azureStorages)
    const instanceID = useSelector((state) => state.azure.azureStorageID)
    const [value, setValue] = React.useState([])
    const dispatch = useDispatch()

    const initFetch = useCallback(() => {
        dispatch(azureStorageList())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    const getDeviceData = useCallback((azureInstance) => {
        var url_string = azureInstance.id
        if (url_string != undefined) {
            var id = url_string.split('/')[4]
            console.log(id, azureInstance.name)
            Azure.getAzureStorageById(id, azureInstance.name).then(function (
                response
            ) {
                setValue(response)
            })
        }
    }, [])

    useEffect(() => {
        if (instanceID != null) {
            getDeviceData(instanceID)
        }
    }, [instanceID])

    const columns = useMemo(
        () => [
            {
                Header: 'Device',
                columns: [
                    {
                        Header: 'Id',
                        accessor: 'id',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.name}
                                title={props.row.original.id}
                            />
                        ),
                    },
                    {
                        Header: 'Name',
                        accessor: 'name',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.name}
                                title={props.row.original.name}
                            />
                        ),
                    },
                    {
                        Header: 'Location',
                        accessor: 'location',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.name}
                                title={props.row.original.location}
                            />
                        ),
                    },
                    {
                        Header: 'Type',
                        accessor: 'type',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.name}
                                title={props.row.original.type}
                            />
                        ),
                    },
                    {
                        Header: 'Creation Time',
                        accessor: 'creation_time',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.name}
                                title={props.row.original.creation_time}
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'sku',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'sku.name',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.name}
                                title={props.row.original.sku.name}
                            />
                        ),
                    },
                    {
                        Header: 'Tier',
                        accessor: 'sku.tier',
                        Cell: (props) => (
                            <ClickableCell
                                id={props.row.original.id}
                                name={props.row.original.name}
                                title={props.row.original.sku.tier}
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue={value}
            title="Cloud Storages"
        />
    )
}

export default StorageAccounts
