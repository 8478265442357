import React from 'react'

const ScopeForm = ({ data, handlePrev, handleNext, closeNew }) => {
    return (
        <form>
            <h4>Scope</h4>
            <p>
                Define the range of the Hosts to be inspected for this policy.
            </p>
            <div className="policy-container">
                <div className="table-responsive policy-table mw-120">
                    <table>
                        <tbody>
                            <tr className="bbtm">
                                <td>
                                    <b>Segments</b>
                                </td>
                                <td>
                                    <b>IP Addresses</b>
                                </td>
                            </tr>
                            <tr>
                                <td>No Name Assigned</td>
                                <td>All IPv4, All IPv6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <button className="btn grn-btn">Add</button>
                    <button className="btn gray-btn">Remove</button>
                    <button className="btn grn-btn">Segments</button>
                </div>
            </div>
            <div className="policy-container1">
                <button className="btn grn-btn" onClick={handlePrev}>
                    Back
                </button>
                <button className="btn grn-btn" onClick={handleNext}>
                    Next
                </button>
                <button className="btn grn-trans-btn" onClick={closeNew}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default ScopeForm
