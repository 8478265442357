import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Azure from '../../services/azure.service'

const initialState = {
    azureDevices: [],
    azureInstance: {},
    azureStorages: [],
    azureStorageID: {},
    azureResources: [],
    azureResourceID: {},
}

export const azureDeviceList = createAsyncThunk(
    'azure/devicelist',
    async () => {
        const res = await Azure.getAzureDevices()
        return res
    }
)
export const getAzureInstance = createAsyncThunk(
    'azure/azureid',
    async ({ id, name }) => {
        const res = { id, name }
        console.log(res)
        return res
    }
)

export const azureStorageList = createAsyncThunk(
    'azure/storagelist',
    async () => {
        const res = await Azure.getAzureStorages()
        return res
    }
)
export const getAzureStorageInstance = createAsyncThunk(
    'azure/storageid',
    async ({ id, name }) => {
        const res = { id, name }
        return res
    }
)
export const azureResourcesList = createAsyncThunk(
    'azure/resourcelist',
    async () => {
        const res = await Azure.getAzureStorages()
        return res
    }
)
export const getAzureResourceInstance = createAsyncThunk(
    'azure/resourceid',
    async ({ id, type }) => {
        const res = { id, type }
        return res
    }
)

export const azureSlice = createSlice({
    name: 'azure',
    initialState,
    extraReducers: {
        [azureDeviceList.fulfilled]: (state, action) => {
            state.azureDevices = action.payload
        },
        [getAzureInstance.fulfilled]: (state, action) => {
            state.azureInstance = action.payload
        },
        [azureStorageList.fulfilled]: (state, action) => {
            state.azureStorages = action.payload
        },
        [getAzureStorageInstance.fulfilled]: (state, action) => {
            state.azureStorageID = action.payload
        },
        [azureResourcesList.fulfilled]: (state, action) => {
            state.azureResources = action.payload
        },
        [getAzureResourceInstance.fulfilled]: (state, action) => {
            state.azureResourceID = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
const { reducer } = azureSlice

export default reducer
