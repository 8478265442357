import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { register } from '../redux/slices/auth'
import { clearMessage } from '../redux/slices/message'

const Register = () => {
    let navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const { message } = useSelector((state) => state.message)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(clearMessage())
    }, [dispatch])

    const formik = useFormik({
        initialValues: {
            name: '',
            username: '',
            email: '',
            password: '',
            agreeTerms: false,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Please enter name'),
            username: Yup.string().required('Please enter username'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Please enter email'),
            password: Yup.string().required('Please enter password'),
            agreeTerms: Yup.boolean(),
        }),
        onSubmit: (values) => {
            const { email, password } = values
            setLoading(true)
            dispatch(register({ email, password }))
                .unwrap()
                .then(() => {
                    navigate('/login')
                })
                .catch(() => {
                    setLoading(false)
                })
        },
    })

    console.log(formik.values.agreeTerms)
    return (
        <div className="auth-form">
            <div className="card card-container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="text-center mb-3">
                        <h4>Sign up with</h4>

                        <div
                            className="d-flex justify-content-between mx-auto social-icons"
                            style={{ width: '40%' }}
                        >
                            <span className="si-link">
                                <i className="fa-brands fa-facebook"></i>
                            </span>
                            <span className="si-link">
                                <i className="fa-brands fa-twitter"></i>
                            </span>
                            <span className="si-link">
                                <i className="fa-brands fa-google"></i>
                            </span>
                            <span className="si-link">
                                <i className="fa-brands fa-github"></i>
                            </span>
                        </div>

                        <div className="clearfix or-text">
                            <span>Or</span>
                        </div>
                    </div>

                    {message && <Alert severity="error">{message}</Alert>}
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        size="normal"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.name)}
                        helperText={formik.errors.name}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        size="normal"
                        variant="outlined"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.confirmPassword &&
                            Boolean(formik.errors.username)
                        }
                        helperText={formik.errors.username}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        size="normal"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.email)}
                        helperText={formik.errors.email}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        size="normal"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.password)}
                        helperText={formik.errors.password}
                        margin="normal"
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="agreeTerms"
                                value={formik.values.agreeTerms}
                            />
                        }
                        label="I have read and agree to the terms"
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        sx={{ marginBottom: '15px' }}
                        disabled={!formik.dirty}
                        type="submit"
                    >
                        {loading && (
                            <span className="fa fa-spin fa-spinner"></span>
                        )}
                        <span>Signup</span>
                    </Button>
                    <p className="text-center">
                        Already have an account ? <Link to="/login">Login</Link>
                    </p>
                </form>
            </div>
        </div>
    )
}

export default Register
