import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../containers/Table.css'
import { gcpResourcesList } from '../redux/slices/gcp'
import Dashboard from '../containers/Dashboard'

function GcpResources(props) {
    const data = useSelector((state) => state.gcp.gcpResources)
    const dispatch = useDispatch()

    const initFetch = useCallback(() => {
        dispatch(gcpResourcesList())
    }, [dispatch])

    useEffect(() => {
        initFetch()
    }, [initFetch])

    const columns = useMemo(
        () => [
            {
                Header: 'Organization',
                accessor: 'organization',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Location',
                accessor: 'location',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Count',
                accessor: 'count',
            },
        ],
        []
    )

    return (
        <Dashboard
            apiData={data}
            columnsData={columns}
            instanceValue=""
            title="Gcp Resources"
        />
    )
}

export default GcpResources
