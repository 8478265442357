import React from 'react'

function About() {
    return (
        <div className="container">
            <div className="card mt-4">
                <div className="card-body">
                    <h2>About Page</h2>
                    <p>Under construction</p>
                </div>
            </div>
        </div>
    )
}
export default About
