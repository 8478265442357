import requests from './httpClient'

const Azure = {
    getAzureDevices(body) {
        return requests.get('/azureDevices')
    },
    getAzureDeviceById(id, name) {
        console.log(id, name)
        return requests.get(`/azureDevices/${id}/${name}`)
    },
    getAzureStorages(body) {
        return requests.get('/azureStorages')
    },
    getAzureStorageById(id, name) {
        return requests.get(`/azureStorages/${id}/${name}`)
    },
    getAzureResources(body) {
        return requests.get('/azureResources')
    },
    getAzureResourceById({ id, type }) {
        return requests.get(`/azureResources?type=${type}&&id=${id}`)
    },
}

export default Azure
