import React, { useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { truncate } from '../utils/constants'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
}

const labels = ['Bid price']

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => [4]),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => [1]),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
}
function CatalogCard({ item }) {
    const [loadMore, setLoadMore] = useState(false)

    const toggleMore = () => setLoadMore(!loadMore)
    const description = loadMore
        ? item[0].description
        : truncate(item[0].description, 75)

    return (
        <div className="card scatg-card mt-4 mb-4">
            <div className="card-body">
                <div className="scatg-wrapper">
                    <div className="bar-wrap">
                        <Bar
                            options={options}
                            width="450"
                            height="250"
                            data={{
                                labels,
                                datasets: [
                                    {
                                        label: 'Minbid',
                                        data: labels.map(() => [
                                            item[0].min_bid,
                                        ]),
                                        backgroundColor:
                                            'rgba(255, 99, 132, 0.5)',
                                    },
                                    {
                                        label: 'Avgbid',
                                        data: labels.map(() => [
                                            item[0].avg_bid,
                                        ]),
                                        backgroundColor:
                                            'rgba(53, 162, 235, 0.5)',
                                    },
                                    {
                                        label: 'Maxbid',
                                        data: labels.map(() => [
                                            item[0].max_bid,
                                        ]),
                                        backgroundColor:
                                            'rgba(160, 245, 88, 0.5)',
                                    },
                                ],
                            }}
                        />
                    </div>

                    <div className="catlog-center">
                        <div className="d-flex">
                            <div className="w-125">
                                <b>Title:</b>
                            </div>
                            <div title={item[0].title}>
                                {truncate(item[0].title, 50)}
                                <span className="id-catlog">
                                    ({item[0].ID})
                                </span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="w-125">
                                <b>Description:</b>
                            </div>
                            <div>
                                <span>{description}</span>
                                <span
                                    className="show-more"
                                    onClick={toggleMore}
                                >
                                    {loadMore ? 'Show Less' : 'Show More'}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <span className="w-125">
                                <b>Category:</b>
                            </span>
                            <span>{item[0].category}</span>
                        </div>
                        <div className="d-flex">
                            <span className="w-125">
                                <b>From:</b>
                            </span>
                            <span>{item[0].data_from}</span>
                        </div>
                    </div>
                    <div className="sc-links">
                        <img
                            src={
                                item[0].image == ''
                                    ? '/images/default.jpeg'
                                    : `${
                                          item[0].data_from == 'comp1'
                                              ? 'https://d1r0e4wfdidpwo.cloudfront.net/product'
                                              : 'https://cronimages.auction.io/uploads/product'
                                      }/${item[0].image}`
                            }
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CatalogCard
