import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Sidebar from '../common/Sidebar'
import Modal from '@mui/material/Modal'
import SettingsModal from '../SettingsModal'

const DashboardLayout = ({ children, ...props }) => {
    const [setting, setSetting] = useState(false)
    const closeNew = () => setSetting(false)
    const openSetting = () => setSetting(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className="complaint-view">
            <div className="db-layout-top">
                <div className="header-logo">
                    <img src="/images/mainlogo.svg" alt="Cyber Software" />
                </div>
                <div className="d-flex justify-space-between dl-right">
                    <ul className="litst-inline top-menu">
                        <li className="litst-inline-item">
                            <NavLink
                                to="/allresources"
                                className={({ isActive }) =>
                                    isActive
                                        ? 'top-menu-link active'
                                        : 'top-menu-link'
                                }
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className="litst-inline-item">
                            <NavLink to="/assets-inventory">
                                Assets Inventory
                            </NavLink>
                        </li>
                        <li className="litst-inline-item">
                            <NavLink to="/policy-rules">Policy</NavLink>
                        </li>
                        <li className="litst-inline-item">
                            <NavLink to="/">Dashboards</NavLink>
                        </li>
                        <li>
                            <div>
                                <Button
                                    id="menu-list-button"
                                    aria-controls={
                                        open ? 'menu-list' : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <i className="fa-solid fa-ellipsis"></i>
                                </Button>
                                <Menu
                                    id="menu-list"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'menu-list-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>
                                        Segmentation
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        Assets Portal
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        Reports
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        User Portal Builder
                                    </MenuItem>
                                </Menu>
                            </div>
                        </li>
                    </ul>
                    <div className="ml-auto">
                        <a className="setting-link" onClick={openSetting}>
                            <i className="fa-solid fa-gear"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="complaint-mainview">
                <Sidebar />
                <div className="complaint-view-wrapper">{children}</div>
            </div>
            <Modal open={setting} onClose={closeNew}>
                <SettingsModal closeNew={closeNew} />
            </Modal>
        </div>
    )
}
export default DashboardLayout
