import React, { useMemo, useState, useEffect } from 'react'
import Table from '../containers/Table'
import '../containers/Table.css'
import Button from 'react-bootstrap/Button'
import DeviceInfo from './DeviceInfo'
import Offcanvas from 'react-bootstrap/Offcanvas'
import httpClient from '../services/httpClient'

const NameList = ({ values, instanceID }) => {
    //console.log('Sreekar'+instanceID);
    return (
        <>
            {values.map((genre, idx) => (
                <OffCanvasExample
                    key={idx}
                    instanceID={instanceID}
                    placement="bottom"
                    name={genre}
                />
            ))}
        </>
    )
}
function Nmap() {
    const columns = useMemo(
        () => [
            {
                Header: 'Device',
                columns: [
                    {
                        Header: 'IPv4 Address',
                        accessor: 'ip',
                        Cell: (props) => (
                            <OffCanvasExample
                                placement="bottom"
                                instanceID={props.row.original.id}
                                name={props.row.original.ip}
                            />
                        ),
                        //Cell: ({ cell: { value } }) => <OffCanvasExample  placement='bottom' name={value} />
                    },
                    {
                        Header: 'Mac Address',
                        accessor: 'mac',
                        Cell: (props) => (
                            <OffCanvasExample
                                placement="bottom"
                                instanceID={props.row.original.id}
                                name={props.row.original.mac}
                            />
                        ),
                    },
                ],
            },
            {
                Header: 'Details',
                columns: [
                    {
                        Header: 'Protocol',
                        accessor: 'reason',
                        Cell: (props) => (
                            <OffCanvasExample
                                placement="bottom"
                                instanceID={props.row.original.id}
                                name={props.row.original.reason}
                            />
                        ),
                    },
                    {
                        Header: 'Device Name',
                        accessor: 'name',
                        //Cell: (props) => (<NameList id={props.row.original.id} values={props.row.original.name} />)
                        Cell: (props) => (
                            <NameList
                                instanceID={props.row.original.id}
                                values={props.row.original.name}
                            />
                        ),
                        //Cell: ({ cell: { value } }) => <NameList instanceID={'xyz'} values={value} />
                    },
                    {
                        Header: 'Vendor',
                        accessor: 'vendor',
                        Cell: (props) => (
                            <NameList
                                instanceID={props.row.original.id}
                                values={props.row.original.vendor}
                            />
                        ),
                        //Cell: ({ cell: { value } }) => <NameList values={value} />
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        Cell: (props) => (
                            <OffCanvasExample
                                placement="bottom"
                                instanceID={props.row.original.id}
                                name={props.row.original.status}
                            />
                        ),
                    },
                ],
            },
        ],
        []
    )

    const [data, setData] = useState([])

    useEffect(() => {
        ;(async () => {
            await httpClient.get('/devicelist').then(function (response) {
                console.log(response)
                setData(response.data)
            })
        })()
    }, [])

    return (
        <div>
            <Table columns={columns} data={data} />
        </div>
    )
}

// function getDetails(instanceID){
//     (async () => {
//         await axios({
//           method: 'get',
//           mode: 'cors',
//           headers: { 'Content-Type': 'application/json'},
//           url: "http://localhost:5000/awsdevicedetails/"+instanceID,
//         }).then(function (response) {
//           console.log(response);
//           //setData(response.data);
//         });
//       })();
//}
function OffCanvasExample({ name, instanceID, ...props }) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
        //getDetails('i-03948a8954fa9b379');
    }

    return (
        <>
            <Button
                variant="btn bg-transparent "
                onClick={handleShow}
                className="me-2"
                disabled
            >
                {name}
            </Button>
            <Offcanvas show={show} height={500} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Showing the Information for InstanceID: {instanceID}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <DeviceInfo instanceID={instanceID} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Nmap
