import React from 'react'
import { useSelector } from 'react-redux'
import SubRuleForm from './SubRuleForm'
import Modal from '@mui/material/Modal'

const SubRules = ({ value, handlePrev, handleNext, closeNew }) => {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const rules = useSelector((state) => state.policy.userPolices)
    return (
        <>
            <div>
                <h4>Sub-Rules</h4>
                <p>Use this screen to review policy sub-rule definitions.</p>
                <p>
                    Hosts are inspected by each sub-rule in the order shown.
                    When a match is found, the action defined is applied. If no
                    match is found, the host is inspected against the next
                    sub-rule.
                </p>
                <div className="mpc-head">Name</div>
                <div className="policy-container">
                    <div className="table-responsive policy-table mw-120">
                        <table className="no-border">
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>{value.policy}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>None</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <button className="btn grn-btn">Edit</button>
                    </div>
                </div>
                <div className="mpc-head">Scope</div>
                <div className="policy-container">
                    <div className="table-responsive policy-table mw-120">
                        <table className="no-border">
                            <tbody>
                                <tr>
                                    <td>IP Ranges</td>
                                    <td>All IPv6, All IPv4</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <button className="btn grn-btn">Edit</button>
                    </div>
                </div>
                <div className="mpc-head">Sub - Rule</div>
                <div className="policy-container">
                    <div className="table-responsive policy-table mw-120">
                        <table>
                            <thead className="">
                                <tr>
                                    <td>
                                        <b>Name</b>
                                    </td>
                                    <td>
                                        <b>Value</b>
                                    </td>
                                    <td>
                                        <b>Operator</b>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {value.id === rules[0].policyID &&
                                    rules[0].subrules.map((rule) => (
                                        <tr>
                                            <td>{rule.title}</td>
                                            <td>{rule.value}</td>
                                            <td>{rule.operator}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <button className="btn grn-btn" onClick={handleOpen}>
                            Add
                        </button>
                        <button className="btn gray-btn">Edit</button>
                        <button className="btn gray-btn">Remove</button>
                        <button className="btn gray-btn">Duplicate</button>
                        <button className="btn gray-btn">Up</button>
                        <button className="btn gray-btn">Down</button>
                    </div>
                </div>
                <div className="policy-container1">
                    <button className="btn grn-btn" onClick={handlePrev}>
                        Back
                    </button>
                    <button
                        type="button"
                        className="btn grn-btn"
                        onClick={closeNew}
                    >
                        Finish
                    </button>
                </div>
            </div>
            <Modal open={open} onClose={handleClose}>
                <SubRuleForm value={value} closeModal={handleClose} />
            </Modal>
        </>
    )
}

export default SubRules
