export const baseURL =
    process.env.NODE_ENV !== 'production'
        ? 'http://localhost:5000'
        : 'https://api.cybersoftware.com'

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export const truncate = (string, number) => {
    return string.length > number
        ? string.substring(0, number - 4) + '... '
        : string
}
