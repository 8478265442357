import { Routes, Route } from 'react-router-dom'
import Home from '../views/Home'
import Login from '../views/Login'
import Register from '../views/Register'
import Contact from '../views/Contact'
import About from '../views/About'
import Aws from '../views/Aws'
import Awss3 from '../views/Awss3'
import AllAws from '../views/AllAws'
import Nmap from '../views/Nmap'
import AssetsInventory from '../views/AssetsInventory'
import PolicyRules from '../views/PolicyRules'
import SmartCatloging from '../views/SmartCatloging'
import CategoryPrediction from '../views/CategoryPrediction'
import ComputeEngines from '../views/ComputeEngines'
import CloudStorages from '../views/CloudStorages'
import VirtualMachines from '../views/VirtualMachines'
import StorageAccounts from '../views/StorageAccounts'
import AzureResources from '../views/AzureResources'
import GcpResources from '../views/GcpResources'

const RoutesData = [
    { path: '/', exact: true, element: Aws, name: 'Aws' },
    { path: '/login', exact: true, element: Login, name: 'Login' },
    { path: '/register', exact: true, element: Register, name: 'Register' },
    { path: '/contact', exact: true, element: Contact, name: 'Contact' },
    { path: '/about', exact: true, element: About, name: 'About' },
    { path: '/awsdevicelist', exact: true, element: Aws, name: 'Aws' },
    {
        path: '/compute-engines',
        exact: true,
        element: ComputeEngines,
        name: 'Compute Engines',
    },
    {
        path: '/cloud-storages',
        exact: true,
        element: CloudStorages,
        name: 'Cloud Storages',
    },
    {
        path: '/virtual-machines',
        exact: true,
        element: VirtualMachines,
        name: 'Virtual Machines',
    },
    {
        path: '/storage-accounts',
        exact: true,
        element: StorageAccounts,
        name: 'Storage Accounts',
    },
    {
        path: '/azure-resources',
        exact: true,
        element: AzureResources,
        name: 'Azure Resources',
    },
    {
        path: '/gcp-resources',
        exact: true,
        element: GcpResources,
        name: 'Gcp Resources',
    },
    { path: '/s3aws', exact: true, element: Awss3, name: 'S3AWS' },
    { path: '/nmap', exact: true, element: Nmap, name: 'Nmap' },
    { path: '/allresources', exact: true, element: AllAws, name: 'AllAWS' },
    {
        path: '/smart-catloging',
        exact: true,
        element: SmartCatloging,
        name: 'Smart Catloging',
    },
    {
        path: '/assets-inventory',
        exact: true,
        element: AssetsInventory,
        name: 'Assets Inventory',
    },
    {
        path: '/policy-rules',
        exact: true,
        element: PolicyRules,
        name: 'Policy',
    },
    {
        path: '/category-prediction',
        exact: true,
        element: CategoryPrediction,
        name: 'Category Prediction',
    },
]

const RoutesLayout = (props) => {
    return (
        <Routes>
            {RoutesData.map((route, index) => (
                <Route
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    element={<route.element />}
                />
            ))}
        </Routes>
    )
}

export default RoutesLayout
