import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { addPolicy, userPolices, clearNote } from '../../redux/slices/policies'
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const SubRuleForm = ({ value, closeModal, props }) => {
    const [open, setOpen] = useState(false)
    const note = useSelector((state) => state.policy.note)
    const availableRegions = useSelector((state) => state.aws.availableRegions)
    const dispatch = useDispatch()
    const [regionNames, setRegionNames] = useState([])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    const handleSelect = (event) => {
        const {
            target: { value },
        } = event
        setRegionNames(typeof value === 'string' ? value.split(',') : value)
    }
    const formik = useFormik({
        initialValues: {
            policyname: '',
            value: [],
            operator: 'contains',
            policyid: value.id,
        },
        validationSchema: Yup.object({
            policyname: Yup.string('Select Policy Name').required(),
            value: Yup.array('Enter policy value').required(),
            operator: Yup.string('Select operator').required(),
        }),
        onSubmit: (values) => {
            values = { ...values, value: regionNames.toString() }
            console.log(values)
            dispatch(addPolicy(values))
                .unwrap()
                .then((res) => {
                    dispatch(userPolices())
                    dispatch(clearNote())
                    closeModal()
                })
                .catch(() => {})
        },
    })

    return (
        <div className="modal-box new-policy">
            <div className="modal-head-top">
                <h1>Add Sub Rule</h1>
                <span onClick={closeModal}>
                    <i className="fa-solid fa-close"></i>
                </span>
            </div>
            <form onSubmit={formik.handleSubmit} className="mt-3">
                <TextField
                    id="policyname"
                    name="policyname"
                    label="Name"
                    value={formik.values.policyname}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.policyname)}
                    helperText={formik.errors.policyname}
                    fullWidth
                    className="mt-3"
                />

                <Select
                    labelId="regions"
                    id="regions"
                    name="value"
                    multiple
                    value={regionNames}
                    onChange={handleSelect}
                    input={<OutlinedInput label="Value" />}
                    fullWidth
                    className="mt-3"
                >
                    {availableRegions.map((name) => (
                        <MenuItem key={name} value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    id="operator"
                    name="operator"
                    label="Operator"
                    value={formik.values.operator}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.operator)}
                    helperText={formik.errors.operator}
                    select
                    fullWidth
                    className="mt-3"
                    {...props}
                >
                    <MenuItem value="contains">Contains</MenuItem>
                    <MenuItem value="==">Equals</MenuItem>
                </TextField>
                <div className="policy-container1">
                    <button type="submit" className="btn grn-btn">
                        Add
                    </button>
                </div>
            </form>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {note}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SubRuleForm
