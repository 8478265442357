import React, { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import policyService from '../../services/policy.service'
import NameForm from './NameForm'
import ScopeForm from './ScopeForm'
import SubRules from './SubRules'

const PolicyForm = ({ closeNew }) => {
    const [data, setData] = React.useState([])
    const [activeStep, setActiveStep] = React.useState(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await policyService.getTemplatePolicies().then((res) => {
                setData(res)
            })
        }

        fetchData()
    }, [])

    const [value, setValue] = useState([])
    return (
        <div className="modal-box new-policy-import">
            <div className="modal-head-top">
                <h1>Add Policy</h1>
                <span onClick={closeNew}>
                    <i className="fa-solid fa-close"></i>
                </span>
            </div>

            {activeStep == 0 && (
                <>
                    <h4>Policy Name</h4>
                    <p>
                        Create a policy using a template or create a custom
                        policy.
                    </p>
                    <div className="row">
                        <div className="col-md-6">
                            <RadioGroup>
                                {data.map((item) => (
                                    <TreeView
                                        aria-label="file system navigator"
                                        defaultCollapseIcon={
                                            <i className="fa fa-angle-down" />
                                        }
                                        defaultExpandIcon={
                                            <i className="fa fa-angle-right" />
                                        }
                                    >
                                        <TreeItem
                                            nodeId={item.module}
                                            label={item.module}
                                        >
                                            <TreeItem
                                                nodeId={item.submodule}
                                                label={item.submodule}
                                            >
                                                {item.policies.map((data) => (
                                                    <FormControlLabel
                                                        value={data.policy}
                                                        control={<Radio />}
                                                        label={data.policy}
                                                        onChange={() =>
                                                            setValue(data)
                                                        }
                                                    />
                                                ))}
                                            </TreeItem>
                                        </TreeItem>
                                    </TreeView>
                                ))}
                            </RadioGroup>
                        </div>
                        {value !== [] && (
                            <div className="col-md-6">
                                <h6>{value.policy}</h6>
                                <p>{value.module}</p>
                            </div>
                        )}
                    </div>
                    <div className="policy-container1">
                        <button
                            className="btn grn-trans-btn"
                            onClick={closeNew}
                        >
                            Cancel
                        </button>
                        <button className="btn grn-btn" onClick={handleNext}>
                            Next
                        </button>
                    </div>
                </>
            )}
            {activeStep == 1 && (
                <NameForm
                    name={value.policy}
                    closeNew={closeNew}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                />
            )}

            {activeStep == 2 && (
                <ScopeForm
                    closeNew={closeNew}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                />
            )}

            {activeStep == 3 && (
                <SubRules
                    closeNew={closeNew}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    value={value}
                />
            )}
        </div>
    )
}

export default PolicyForm
