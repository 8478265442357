import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { FilterMenu } from './FilterMenu'
import { useSelector } from 'react-redux'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<i className="fa-solid fa-chevron-right" />}
        {...props}
    />
))(({ theme }) => ({
    background: 'none',
    padding: '0',
    margin: 0,
    flexDirection: 'row-reverse',
    minHeight: 25,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        padding: '0',
        margin: '0',
    },

    '& .MuiListItemButton-root': {
        padding: '0',
    },

    '& .MuiTypography-root': {
        fontSize: 12,
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '10px 0 0 40px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',

    '& .MuiTypography-root': {
        fontSize: 12,
    },
}))

const Sidebar = () => {
    const [expanded, setExpanded] = React.useState('')

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    //const dispatch = useDispatch();
    const polices = useSelector((state) => state.policy.userPolices)
    //const [url, setUrl] = useState("");
    const [open, setOpen] = React.useState('')
    const handleClick = (menu) => (event) => {
        console.log(menu)
        setOpen(menu)
    }

    return (
        <div className="sidebar-view">
            <div className="view-area">
                <div className="views">
                    <div className="searchSpace">
                        <input type="search" placeholder="search" />
                        <img src="../images/Icon-feather-search.svg" alt="" />
                    </div>
                </div>
                <div className="view-menus">
                    <ul className="first-menu">
                        <li>
                            <Link to="/allresources">
                                <img src="/images/hosting.svg" alt="hosting" />
                                <span>All Hosts(20)</span>
                            </Link>
                        </li>
                    </ul>

                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        sx={{ mt: '15px' }}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                        >
                            <ListItemButton sx={{ padding: '0 10px' }}>
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    <img
                                        src="/images/insurance.svg"
                                        alt="policy"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Policies" />
                            </ListItemButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <RadioGroup>
                                {polices.map((ele, index) => (
                                    <TreeView
                                        aria-label="file system navigator"
                                        defaultCollapseIcon={
                                            <i className="fa fa-angle-down" />
                                        }
                                        defaultExpandIcon={
                                            <i className="fa fa-angle-right" />
                                        }
                                    >
                                        <TreeItem
                                            nodeId={ele.policy}
                                            label={ele.policy}
                                            className="policy-rule"
                                        >
                                            {ele?.subrules.map(
                                                (policy, index) => (
                                                    <FormControlLabel
                                                        id={`policy-rule-${index}`}
                                                        value={policy.title}
                                                        control={<Radio />}
                                                        label={policy.title}
                                                    />
                                                )
                                            )}
                                        </TreeItem>
                                    </TreeView>
                                ))}
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                    >
                        <AccordionSummary
                            aria-controls="panel2d-content"
                            id="panel2d-header"
                        >
                            <ListItemButton>
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    <img
                                        src="/images/Icon-ionic-ios-folder.svg"
                                        alt="folder"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="1.1 Discover" />
                            </ListItemButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List component="div" sx={{ pl: 4 }}>
                                <ListItemButton>
                                    <ListItemText primary="Discover" />
                                </ListItemButton>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel3'}
                        onChange={handleChange('panel3')}
                    >
                        <AccordionSummary
                            aria-controls="panel3d-content"
                            id="panel3d-header"
                        >
                            <ListItemButton>
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    <img
                                        src="/images/Icon-ionic-ios-folder.svg"
                                        alt="folder"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="1.2 Classify" />
                            </ListItemButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List component="div" sx={{ pl: 4 }}>
                                <ListItemButton>
                                    <ListItemText primary="Classify" />
                                </ListItemButton>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel4'}
                        onChange={handleChange('panel4')}
                    >
                        <AccordionSummary
                            aria-controls="panel4d-content"
                            id="panel4d-header"
                        >
                            <ListItemButton>
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    <img
                                        src="/images/Icon-ionic-ios-folder.svg"
                                        alt="folder"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="1.3 Assess" />
                            </ListItemButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List component="div" sx={{ pl: 4 }}>
                                <ListItemButton>
                                    <ListItemText primary="Assess" />
                                </ListItemButton>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel5'}
                        onChange={handleChange('panel5')}
                    >
                        <AccordionSummary
                            aria-controls="panel5d-content"
                            id="panel5d-header"
                        >
                            <ListItemButton>
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    <img
                                        src="/images/Icon-ionic-ios-folder.svg"
                                        alt="folder"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Assets Clarssification(18)" />
                            </ListItemButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List component="div" sx={{ pl: 4 }}>
                                <ListItemButton>
                                    <ListItemText primary="Assets" />
                                </ListItemButton>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'panel6'}
                        onChange={handleChange('panel6')}
                    >
                        <AccordionSummary
                            aria-controls="panel6d-content"
                            id="panel6d-header"
                        >
                            <ListItemButton>
                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                    <img
                                        src="/images/Icon-awesome-history.svg"
                                        alt="history"
                                    />
                                </ListItemIcon>
                                <ListItemText primary="History" />
                            </ListItemButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List component="div" sx={{ pl: 4 }}>
                                <ListItemButton>
                                    <ListItemIcon sx={{ minWidth: '30px' }}>
                                        <img
                                            src="/images/Icon-awesome-file-alt.svg"
                                            alt="policy"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="NAC Policy" />
                                </ListItemButton>
                            </List>
                        </AccordionDetails>
                    </Accordion>
                    <ul className="first-menu">
                        <li>
                            <Link to="/">
                                <img
                                    src="/images/Icon-awesome-file-alt.svg"
                                    alt="policy"
                                />
                                <span>Imsi</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <div className="filter-area">
                <div className="filter">
                    <p>Filters</p>
                    <div className="searchSpace">
                        <input type="search" placeholder="search" />
                        <img src="../images/search.svg" alt="" />
                    </div>
                </div>

                <ul className="list-unstyled p-0">
                    {FilterMenu.map((item, index) => {
                        return (
                            <li key={`sub-menu-${index}`}>
                                {item.subNav == [] ? (
                                    <Link to="/">
                                        <img
                                            src={`/images/${item.icon}`}
                                            alt={item.title}
                                        />
                                        <span>{item.title}</span>
                                    </Link>
                                ) : (
                                    <Accordion
                                        expanded={expanded === item.title}
                                        onChange={handleChange(item.title)}
                                        sx={{ mt: '15px' }}
                                    >
                                        <AccordionSummary
                                            aria-controls={`${item.title}-content`}
                                            id={`${item.title}-content`}
                                        >
                                            <ListItemButton
                                                sx={{ padding: '0 10px' }}
                                            >
                                                <ListItemIcon
                                                    sx={{ minWidth: '30px' }}
                                                >
                                                    <img
                                                        src={`/images/${item.icon}`}
                                                        alt={item.title}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.title}
                                                />
                                            </ListItemButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {item.subNav.map((item, index) => {
                                                return (
                                                    <List
                                                        component="div"
                                                        sx={{ padding: 0 }}
                                                        key={`submenu-${index}`}
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                padding:
                                                                    '0 10px',
                                                            }}
                                                            onClick={handleClick(
                                                                `menu-${index}`
                                                            )}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    item.title
                                                                }
                                                            />

                                                            {open ==
                                                            `menu-${index}` ? (
                                                                <i className="fa fa-angle-up" />
                                                            ) : (
                                                                <i className="fa fa-angle-down" />
                                                            )}
                                                        </ListItemButton>
                                                        <Collapse
                                                            in={
                                                                open ==
                                                                `menu-${index}`
                                                            }
                                                            timeout="auto"
                                                            unmountOnExit
                                                        >
                                                            {item.subNav.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <List
                                                                            component="div"
                                                                            sx={{
                                                                                padding: 0,
                                                                            }}
                                                                            key={`submenu-${index}`}
                                                                        >
                                                                            <ListItemButton
                                                                                sx={{
                                                                                    padding:
                                                                                        '0 10px',
                                                                                }}
                                                                            >
                                                                                <Link
                                                                                    to={
                                                                                        item.path
                                                                                    }
                                                                                >
                                                                                    <ListItemText
                                                                                        primary={
                                                                                            item.title
                                                                                        }
                                                                                    />
                                                                                </Link>
                                                                            </ListItemButton>
                                                                        </List>
                                                                    )
                                                                }
                                                            )}
                                                        </Collapse>
                                                    </List>
                                                )
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
export default Sidebar
